import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import { useApiLoader } from "../services/apiLoaderContext";
import { EntityApi, useAuth } from "@unity/components";
import { checkArray } from "../utils/checkArray";
import config from "../config";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#34884c",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: 200,
  width: "100%",
};

export default function FileUploader({ open, setOpen, parentId, onUpdate }) {
  const { auth, checkPermission } = useAuth();
  const { setLoading, setSnackbar } = useApiLoader();

  const [files, setFiles] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [permission, setPermission] = useState(null);
  const [canUpload, setCanUpload] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const extIndex = file.name.lastIndexOf(".");
        const extension = file.name.substring(extIndex);
        const name = file.name.substring(0, extIndex);
        setDroppedFile({
          base64: e.target.result,
          name,
          extension,
          fileType: file.type,
          size: file.size,
        });
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 5,
  });

  useEffect(() => {
    const getParent = async () => {
      try {
        const res = await EntityApi.getEntity(
          parentId,
          config.entityIntranetTypeId
        );
        if (res && res.status === 200) {
          setPermission(res?.data?.permission ? res.data.permission : "");
          setCanUpload(checkPermission(`upload_file_${permission}`));
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (parentId) getParent();
  }, [parentId, checkPermission, permission]);

  useEffect(() => {
    if (droppedFile) {
      setFiles((prev) => [...prev, JSON.parse(JSON.stringify(droppedFile))]);
    }
  }, [droppedFile]);

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const storeDocuments = async () => {
    setLoading(true);
    try {
      const filePromises = files.map((el) =>
        EntityApi.postEntity({
          type_id: config.entityIntranetTypeId,
          entity: {
            ...el,
            ownerId: auth.id,
            parentId,
            permission,
          },
        })
      );
      Promise.all(filePromises).then((res) => {
        const check = res.find((el) => el.status !== 200);
        if (!check) {
          setSnackbar({
            open: true,
            message: "Successfully uploaded",
            success: true,
          });
          onUpdate();
        }
      });
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: JSON.stringify(err), success: false });
    }
    setLoading(false);
    setOpen(false);
    setFiles([]);
    setDroppedFile(null);
  };

  const handleDeleteFile = (file) => {
    let localFiles = [...files];
    setFiles(localFiles.filter((el) => el.name !== file));
  };

  const getChip = (data) => {
    return (
      <Chip
        color="primary"
        label={data?.name || "test"}
        onDelete={() => handleDeleteFile(data.name)}
      />
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogContent>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>{"Drag 'n' drop some files here, or click to select files"}</p>
          )}
        </div>
        {files && checkArray(files) ? (
          <div style={{ marginTop: 30 }}>
            <Grid container spacing={1}>
              {files.map((el, i) => (
                <Grid item key={i}>
                  {getChip(el)}
                </Grid>
              ))}
            </Grid>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={!canUpload}
          variant="contained"
          onClick={storeDocuments}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
