import React from "react";
import { useView } from "../services/viewContext";
import Grid from "./grid";
import Table from "./table";

export default function ViewContainer({ data, ...props }) {
  const { view } = useView();

  return view === "list" ? (
    <Table data={data} {...props} />
  ) : (
    <Grid data={data} {...props} />
  );
}
