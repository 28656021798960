import React, { useEffect, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useParams,
  Link as RouterLink,
} from "react-router-dom";

import Container from "../common/container";
import ViewContainer from "../components/viewContainer";
import CreateFolder from "../common/createFolder";
import FileUploader from "../common/fileUploader";
import { EntityApi, PersonnelApi, useAuth } from "@unity/components";
import config from "../config";
import FileEditor from "./fileEditor";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { checkArray } from "../utils/checkArray";

export default function FileSystem({ root }) {
  const { resourceActions, checkPermission } = useAuth();
  let { path, url } = useRouteMatch();
  const location = useLocation();
  let { directory } = useParams();
  let dirArr =
    directory !== "index"
      ? directory.split("&")
      : [config.rootIntranetDirectoryId];
  let id = dirArr[0];

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [update, setUpdate] = useState(false);
  const [parent, setParent] = useState(null);
  const [userConfirm, setUserConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const availableRoles = resourceActions
        .filter(
          (el) =>
            el.name.indexOf(`read_folder`) !== -1 ||
            el.name.indexOf(`read_file`) !== -1
        )
        .map((el) => {
          const lr = el ? el.name.split("_") : [];
          return lr[2];
        });
      let res = checkArray(availableRoles)
        ? await EntityApi.queryEntityWithinType({
            type_id: config.entityIntranetTypeId,
            queries: [
              { AND: ["parentId", id] },
              { IN: ["permission", [...availableRoles]] },
            ],
          })
        : null;
      if (res && res.status === 200) {
        setData(res.data.data);
      } else {
        setData([]);
      }
    };
    const currSelArr = location.pathname.split("/");
    const currSel = currSelArr[currSelArr.length - 1];
    if (id && currSel && currSel.indexOf(id) !== -1) {
      getData();
    } else if (root) {
      setData([{ ...root }]);
    }
  }, [update, directory, id, resourceActions, root, location.pathname]);

  useEffect(() => {
    const getParent = async () => {
      const res = await EntityApi.getEntity(id, config.entityIntranetTypeId);
      if (res) {
        setParent(res.data);
      }
    };
    const currSelArr = location.pathname.split("/");
    const currSel = currSelArr[currSelArr.length - 1];
    if (id && currSel && currSel.indexOf(id) !== -1) {
      getParent();
    } else {
      setParent({ ...root });
    }
  }, [id, location.pathname, root]);

  const createUserFolders = async () => {
    //get users for tenant and create folder for each under current folder...
    setLoading(true);
    try {
      const users = await PersonnelApi.getPersonnel();
      if (users?.success) {
        const data = users.data.map((el) =>
          EntityApi.postEntity({
            type_id: config.entityIntranetTypeId,
            entity: {
              name: el.name,
              children: [],
              private: el.uuid,
              parentId: id,
              permission: "user",
            },
          })
        );
        const res = Promise.all(data).then((el) => {
          if (el.every((r) => r.success)) {
            setUpdate((prev) => !prev);
            setUserConfirm(false);
            setMenuOpen(null);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const actions = [
    <div key={0}>
      <Button
        onClick={(e) => setMenuOpen(e.currentTarget)}
        variant="contained"
        endIcon={<KeyboardArrowDown />}
        className="tour-intranetnewbutton"
      >
        New...
      </Button>
      <Menu
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen(null)}
      >
        {checkPermission(`create_folder_${parent?.permission}`) ? (
          <MenuItem onClick={() => setOpen(true)}>Folder</MenuItem>
        ) : null}
        {checkPermission(`create_folder_${parent?.permission}`) ? (
          <MenuItem
            onClick={() => setUserConfirm(true)}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            !User Folders!
          </MenuItem>
        ) : null}
        <MenuItem to={`${url}/createdoc`} component={RouterLink}>
          Word Document
        </MenuItem>
        <MenuItem to={`${url}/createxlsx`} component={RouterLink}>
          Excel Document
        </MenuItem>
      </Menu>
    </div>,
    <Button
      key={1}
      onClick={() => setUploadOpen(true)}
      variant="contained"
      className="tour-intranetuploadbutton"
    >
      Upload
    </Button>,
  ];

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Container
            pathname={location.pathname}
            actions={actions.filter((el) => el)}
            update={update}
            root={root}
          >
            <ViewContainer
              data={data}
              update={update}
              onUpdate={() => setUpdate((prev) => !prev)}
              url={url}
            />
          </Container>
        </Route>
        <Route path={`${url}/createdoc`}>
          <FileEditor type="word" parentId={id} />
        </Route>
        <Route path={`${url}/createxlsx`}>
          <FileEditor type="excel" parentId={id} />
        </Route>
        <Route path={`${url}/file/:file`}>
          <FileEditor parentId={id} />
        </Route>
        <Route path={`${url}/:directory`}>
          <FileSystem root={root} />
        </Route>
      </Switch>
      <CreateFolder
        open={open}
        setOpen={setOpen}
        data={formData}
        setData={setFormData}
        title="Create New Folder"
        parentId={id}
        onUpdate={() => setUpdate((prev) => !prev)}
      />
      <FileUploader
        open={uploadOpen}
        setOpen={setUploadOpen}
        parentId={id}
        onUpdate={() => setUpdate((prev) => !prev)}
      />
      <Dialog
        open={userConfirm}
        onClose={() => setUserConfirm(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create folders for users?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setUserConfirm(false)}>Cancel</Button>
          <Button variant="contained" onClick={createUserFolders}>
            Create
          </Button>
          {loading ? <CircularProgress /> : null}
        </DialogActions>
      </Dialog>
    </>
  );
}
