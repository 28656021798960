import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import config from "../config";
import { EntityApi } from "@unity/components";

export default function TemplateSelect({ value, onChange }) {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        let res = await EntityApi.getEntityByType(config.entityTemplateTypeId);
        if (res && res.status === 200) {
          setTemplates(res.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    <FormControl sx={{ mt: 1 }}>
      <InputLabel id="template-label">Select Template</InputLabel>
      <Select
        labelId="template-label"
        id="template"
        label="Select Template"
        value={value || ""}
        onChange={onChange}
      >
        <MenuItem value={""}>None</MenuItem>
        {templates.map((el, i) => (
          <MenuItem key={i} value={JSON.stringify(el)}>
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
