import React, { useState, useEffect, useRef } from "react";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { registerLicense } from "@syncfusion/ej2-base";

import { EntityApi, SyncfusionToolsApi, useAuth } from "@unity/components";
import config from "../config";

import "../spreadsheet.css";
import { useWindowSize } from "../utils/useWindowSize";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5ed3RQRmlcUkRyXkc="
);

export default function SpreadsheetEditor({
  entity,
  parentId,
  permission,
  handleRes,
  NameDialog,
  SaveMenu,
}) {
  let container = useRef(null);
  const { auth } = useAuth();
  const windowSize = useWindowSize();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(entity?.name ? `${entity.name}-Copy` : "");

  useEffect(() => {
    let spreadsheet = container.current;
    if (spreadsheet) {
      spreadsheet.height = windowSize.height - 180;
    }
  }, [windowSize.height]);

  useEffect(() => {
    const onLoadDefault = async () => {
      try {
        let spreadsheet = container.current;
        if (spreadsheet && entity) {
          const res = await SyncfusionToolsApi.openSpreadsheet(entity.base64);
          if (res && res.success && res.Workbook) {
            const localData = JSON.parse(JSON.stringify(res.Workbook));
            spreadsheet.openFromJson({
              file: {
                Workbook: { ...localData, activeSheetIndex: 0 },
              },
            });
            spreadsheet.refresh();
          }
        }
        spreadsheet.refresh(false);
        spreadsheet.hideRibbonTabs(["File"], false);
      } catch (err) {
        console.log(err);
      }
    };
    onLoadDefault();
  }, [entity]);

  const clickHandler = async () => {
    container.current.resize();
    const doc = await container.current.saveAsJson().then((res) => {
      return JSON.stringify(res.jsonObject.Workbook);
    });
    const res = await SyncfusionToolsApi.saveSpreadsheet("Xlsx", doc);
    if (res && res.success) {
      const formData = {
        type_id: config.entityIntranetTypeId,
        entity: {
          name,
          ownerId: auth.id,
          parentId,
          permission,
          base64: res.base64,
          extension: ".xlsx",
          fileType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      };
      const res1 = await EntityApi.postEntity(formData);
      handleRes(res1);
    }
  };

  return (
    <>
      <SaveMenu
        onSaveCopy={() => {
          const spreadsheet = container.current;
          spreadsheet.endEdit();
          setOpen(true);
        }}
      />

      <SpreadsheetComponent
        ref={container}
        fileMenuBeforeOpen={() => {
          container.current.hideFileMenuItems(["New", "Open", "Save As"], true);
        }}
      />

      <NameDialog
        name={name}
        setName={setName}
        onClose={() => setOpen(false)}
        open={open}
        onClick={clickHandler}
      />
    </>
  );
}
