import React, { useEffect, useRef, useState } from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";

import { EntityApi, SyncfusionToolsApi, useAuth } from "@unity/components";
import config from "../config";

import "../doceditor.css";
import { useWindowSize } from "../utils/useWindowSize";
import { Box } from "@mui/material";

DocumentEditorContainerComponent.Inject(Toolbar);

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5ed3RQRmlcUkRyXkc="
);

export default function DocEditor({
  entity,
  parentId,
  permission,
  handleRes,
  NameDialog,
  SaveMenu,
}) {
  let docRef = useRef(null);
  const { auth } = useAuth();
  const windowSize = useWindowSize();

  const [open, setOpen] = useState(false);
  const [itemHeight, setItemHeight] = useState(100);
  const [name, setName] = useState(entity?.name ? `${entity.name}-Copy` : "");

  useEffect(() => {
    setItemHeight(windowSize.height - 150);
    let editor = docRef.current;
    if (editor) {
      editor.resize();
      editor.refresh();
    }
  }, [windowSize.height]);

  useEffect(() => {
    const onLoadDefault = async () => {
      try {
        if (entity?.sfdt) {
          docRef.current.documentEditor.open({ sfdt: entity.sfdt });
        } else {
          const res = await SyncfusionToolsApi.importDocument(
            true,
            entity.base64
          );
          if (res && res.success) {
            if (res.sfdt) {
              docRef.current.documentEditor.open({ sfdt: res.sfdt });
            }
          }
        }
        docRef.current.documentEditor.documentName = entity.name;
        docRef.current.documentEditorSettings.showRuler = true;
      } catch (err) {
        console.log(err);
      }
    };
    const renderComplete = () => {
      try {
        docRef.current.documentEditor.pageOutline = "#E0E0E0";
        docRef.current.documentEditor.acceptTab = true;
        docRef.current.documentEditor.resize();
        onLoadDefault();
      } catch (err) {
        console.log(err);
      }
    };
    renderComplete();
  }, [entity]);

  const clickHandler = async () => {
    try {
      const documenteditor = docRef.current.documentEditor;
      /** Here's the line that gets the entire document as sfdt data into a variable */
      const sfdt = documenteditor.serialize();
      //instead of saving as sfdt, get converted doc from api
      const res = await SyncfusionToolsApi.exportFromSfdt("Docx", sfdt);
      if (res && res.success) {
        const formData = {
          type_id: config.entityIntranetTypeId,
          entity: {
            name,
            ownerId: auth.id,
            parentId,
            permission,
            base64: res.base64,
            fileType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            size: res.size,
            extension: ".docx",
          },
        };
        const res1 = await EntityApi.postEntity(formData);
        handleRes(res1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <SaveMenu onSaveCopy={() => setOpen(true)} />
      <Box sx={{ height: itemHeight }}>
        <DocumentEditorContainerComponent
          ref={docRef}
          enableToolbar={true}
          style={{ display: "block" }}
          height={"100%"}
          serviceUrl={`${config.appURL}/api/documenteditor/`}
        />
      </Box>
      <NameDialog
        name={name}
        setName={setName}
        onClose={() => setOpen(false)}
        open={open}
        onClick={clickHandler}
      />
    </>
  );
}
