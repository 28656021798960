const staging = true;
const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  entityIntranetTypeId: staging
    ? "65562286b03402e85a0b4b02"
    : "66ed511c12b24b70070ba5c2",
  entityTemplateTypeId: staging
    ? "65564781ad0aedacba0c8612"
    : "66ed51aa74745dcf020f7522",
  rootIntranetDirectoryId: staging
    ? "65fd6a969fe7d978bb083c42"
    : "66f284a0c489a34d2506a652",
  defaultRole: "manager",
  resourceName: "intranet",
};
