import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import TemplateSelect from "./templateSelect";
import {
  EntityApi,
  PermissionDropdownComponent,
  useAuth,
} from "@unity/components";
import { useApiLoader } from "../services/apiLoaderContext";
import config from "../config";
import { checkArray } from "../utils/checkArray";

export default function CreateFolder({
  open,
  setOpen,
  title,
  parentId,
  onUpdate,
}) {
  const { setLoading, setSnackbar } = useApiLoader();
  const { auth, checkPermission } = useAuth();
  const canCreate = checkPermission("create_folder_user");

  const [name, setName] = useState("");
  const [permission, setPermission] = useState("");
  const [template, setTemplate] = useState("");

  useEffect(() => {
    const getParent = async () => {
      try {
        const res = await EntityApi.getEntity(
          parentId,
          config.entityIntranetTypeId
        );
        if (res && res.status === 200) {
          setPermission(res?.data?.permission ? res.data.permission : "");
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (parentId) getParent();
  }, [parentId]);

  const handleRes = (res) => {
    if (res && res.status === 200) {
      setSnackbar({
        open: true,
        message: "successfully created",
        success: true,
      });
    } else {
      setSnackbar({ open: true, message: res.errors, success: false });
    }
    setLoading(false);
    setOpen(false);
    onUpdate();
    setTemplate("");
    setName("");
    setPermission("");
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const res = await EntityApi.postEntity({
        type_id: config.entityIntranetTypeId,
        entity: {
          name,
          permission,
          ownerId: auth.id,
          parentId,
          children: [],
        },
      });
      if (res && res.status === 200 && template) {
        handleCreateWithTemplate(res.data._id);
      } else {
        handleRes(res);
      }
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: err, success: false });
    }
  };

  const handleCreateWithTemplate = (parentId) => {
    try {
      const localTemplate = JSON.parse(template);
      if (checkArray(localTemplate.structure)) {
        localTemplate.structure.forEach((item) => {
          createFolder(item, parentId);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createFolder = async (data, parentId) => {
    const res = await EntityApi.postEntity({
      type_id: config.entityIntranetTypeId,
      entity: {
        name: data.name,
        permission,
        ownerId: auth.id,
        parentId,
        children: [],
      },
    });
    if (res && res.status === 200) {
      if (checkArray(data.structure)) {
        const id = res.data._id;
        data.structure.forEach((child) => createFolder(child, id));
      } else {
        handleRes(res);
      }
    } else {
      handleRes(res);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            fullWidth
            sx={{ mt: 1 }}
          />
          <PermissionDropdownComponent
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
          />
          <TemplateSelect
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          disabled={!canCreate}
          variant="contained"
          onClick={handleCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
