import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { useAuth, EntityApi } from "@unity/components";
import config from "./config";
import FileSystem from "./pages/filesystem";

export default function Routes() {
  const { auth } = useAuth();
  const allowUser = auth.access[config.resourceName];

  const [root, setRoot] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let res = await EntityApi.queryEntityWithinType({
        type_id: config.entityIntranetTypeId,
        queries: [{ IN: ["parentId", [null]] }],
      }).catch((err) => console.log(err));
      if (res && res.status === 200) {
        setRoot(res.data.data[0]);
      }
    };
    getData();
  }, []);

  return (
    <Switch>
      <Route path="/location" exact component={Location} />
      {allowUser && (
        <Route path="/intranet/:directory">
          <FileSystem root={root} />
        </Route>
      )}
    </Switch>
  );
}
