import React from "react";
import {
  Link,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import PlayArrow from "@mui/icons-material/PlayArrow";

export default function Breadcrumbs({ pathname }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const locationArray = pathname.split("/").filter((x) => x && x !== "file");

  return (
    <MuiBreadcrumbs
      maxItems={matches ? 5 : 2}
      separator={<PlayArrow fontSize="small" />}
      sx={{ p: 1, flexWrap: "nowrap" }}
    >
      {locationArray
        ? locationArray.map((el, i) => {
            const nameArr = el.split("&");
            const name = nameArr.length > 1 ? nameArr[1] : nameArr[0];
            const routeTo =
              name === "intranet"
                ? "/intranet/index"
                : `/${locationArray.slice(0, i + 1).join("/")}`;
            const isLast = i === locationArray.length - 1;
            return isLast ? (
              <Tooltip title={name}>
                <Typography
                  key={routeTo}
                  color="textSecondary"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {name.substring(0, 100)}
                </Typography>
              </Tooltip>
            ) : (
              <Tooltip title={name}>
                <Link
                  key={routeTo}
                  to={routeTo}
                  component={RouterLink}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {name.substring(0, 100)}
                </Link>
              </Tooltip>
            );
          })
        : null}
    </MuiBreadcrumbs>
  );
}
