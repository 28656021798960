import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Link,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
} from "@mui/material";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { checkArray } from "../utils/checkArray";
import TableRowMenu from "./tableRowMenu";
import { checkFolder } from "../utils/checkFolder";
import { useApiLoader } from "../services/apiLoaderContext";
import { EntityApi, DeleteButtonComponent, useAuth } from "@unity/components";
import config from "../config";
import { useWindowSize } from "../utils/useWindowSize";

/*
Including fix for pagination items not aligned centrally in Mui DataGrid
see issue: https://github.com/mui/mui-x/issues/4076
 */

const CustomToolbar = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <GridToolbarContainer className="tour-intranetmultiselect">
      <Button
        onClick={props.onDownload}
        variant="outlined"
        size="small"
        disabled={!props.enable || !props.allowDownload}
      >
        Download
      </Button>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        size="small"
        disabled={!(props.enable && props.allowDelete)}
      >
        Delete
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <DeleteButtonComponent
            onClick={() => {
              props.executeDelete();
              setOpen(false);
            }}
          >
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
    </GridToolbarContainer>
  );
};

export default function Table({ data, update, onUpdate }) {
  const { url } = useRouteMatch();
  const { setLoading, setSnackbar } = useApiLoader();
  const windowSize = useWindowSize();
  const ref = useRef();
  const { checkPermission, auth, personnelPrivate } = useAuth();

  const [itemHeight, setItemHeight] = useState(100);
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [tableData, setTableData] = useState([]);

  const permitted = (val) =>
    personnelPrivate && val?.private ? val.private === auth.id : true;
  const isFile = (val) =>
    val.extension === ".xlsx" ||
    val.extension === ".docx" ||
    val.extension === ".doc" ||
    val.sfdt;
  const isFolder = (val) => checkFolder(val);

  useEffect(() => {
    setItemHeight(windowSize.height - ref.current.offsetTop - 115);
  }, [windowSize.height]);

  useEffect(() => {
    if (checkArray(data)) {
      setTableData(data.map((el) => ({ ...el, id: el._id })));
    }
  }, [data]);

  const columns = [
    { field: "type", width: 30, renderCell: (props) => getIcon(props.row) },
    {
      field: "name",
      headerName: "Name",
      maxWidth: 300,
      renderCell: (props) => {
        return (
          <Tooltip
            title={`${props.value}${props.row.extension || ""}`}
            placement="bottom-start"
          >
            {permitted(props.row) &&
            (isFile(props.row) || isFolder(props.row)) ? (
              <Link
                to={handleGetLink(props.row)}
                component={RouterLink}
                sx={{ minWidth: 150 }}
              >
                {`${props.value}${props.row.extension || ""}`}
              </Link>
            ) : (
              <Typography variant="body2" sx={{ minWidth: 150 }}>{`${
                props.value
              }${props.row.extension || ""}`}</Typography>
            )}
          </Tooltip>
        );
      },
    },
    { field: "description", headerName: "Description" },
    { field: "created_by_name", headerName: "Owner" },
    { field: "modified", headerName: "Modified" },
    {
      field: "updated_at",
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      headerName: "Updated at",
    },
    {
      field: "created_at",
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      headerName: "Created At",
    },
    { field: "permission", headerName: "Permission" },
    {
      field: "actions",
      type: "actions",
      renderCell: (params) => (
        <TableRowMenu row={params.row} update={update} onUpdate={onUpdate} />
      ),
    },
  ];

  const getIcon = (row) => {
    if (checkFolder(row)) {
      return <FolderIcon />;
    } else {
      return <InsertDriveFileIcon />;
    }
  };

  const handleGetLink = (row) => {
    if (checkFolder(row)) {
      //a folder, so check children
      /*   return url === "/intranet/index"
        ? "/intranet/sbu-list"
        : `${url}/${row._id}&${row.name}`;*/
      return `${url}/${row._id}&${row.name}`;
    } else {
      //is file, so open doc editor
      return `${url}/file/${row._id}&${row.name}`;
    }
  };

  const handleDownload = () => {
    try {
      selected.forEach((el) => {
        const row = data.find((item) => item._id === el);
        const url = row.base64;
        const link = document.createElement("a");
        link.download = row.name;
        link.href = url;
        link.target = "_self";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      //use selected and delete many
      setLoading(true);
      const deletePromises = selected.map(
        async (el) =>
          await EntityApi.deleteEntity(el, config.entityIntranetTypeId)
      );
      Promise.all(deletePromises).then((res) => {
        setDeleteMessage({ ids: [...selected], res: [...res] });
      });
      setSelected([]);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleRestore = async () => {
    try {
      setLoading(true);
      const restorePromises = deleteMessage.ids.map(
        async (el) =>
          await EntityApi.restoreEntity(el, config.entityIntranetTypeId)
      );
      Promise.all(restorePromises).then((res) => {
        const check = res.find((el) => el.status !== 200);
        if (!check) {
          setDeleteMessage(null);
          onUpdate();
          setSnackbar({
            open: true,
            message: "Successfully restored",
            success: true,
          });
        } else {
          setDeleteMessage((prev) => ({ ...prev, restoreRes: [...res] }));
        }
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const checkNoChildren = async (item) => {
    try {
      const res = await EntityApi.getEntityByType(
        config.entityIntranetTypeId,
        item._id
      );
      if (res && res.status === 200) {
        if (res.data.data.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const checkConfirmDelete = async () => {
    const check = selected.filter((selectedEl) => {
      const item = data.find((el) => el._id === selectedEl);
      item.permission !== "user" && checkFolder(item);
    });
    if (check && check.length > 0) {
      const children = check.map((selectedEl) => {
        const item = data.find((el) => el._id === selectedEl);
        checkNoChildren(item);
      });
      if (children && children.includes(false) === false) {
        handleDelete();
      } else {
        setConfirmDelete(true);
      }
    } else {
      handleDelete();
    }
  };

  const handleCheckDownload = () => {
    const localPerm = [];
    selected.forEach((selectedEl) => {
      const row = data.find((el) => el._id === selectedEl);
      const type = checkFolder(row) ? "folder" : "file";
      localPerm.push(
        checkPermission(`download_${type}_${row.permission}`) &&
          (!checkFolder(row) || row.base64 == null)
      );
    });
    return localPerm.some((el) => !el) === false;
  };

  const handleCheckDelete = () => {
    const localPerm = [];
    selected.forEach((selectedEl) => {
      const row = data.find((el) => el._id === selectedEl);
      const type = checkFolder(row) ? "folder" : "file";
      localPerm.push(checkPermission(`delete_${type}_${row.permission}`));
    });
    return localPerm.some((el) => !el) === false;
  };

  return (
    <Box
      ref={ref}
      sx={{ height: itemHeight, overflow: "auto" }}
      className="tour-intranettable"
    >
      <DataGrid
        checkboxSelection
        disableRowSelectionOnClick
        columns={columns}
        rows={tableData}
        rowSelectionModel={selected}
        onRowSelectionModelChange={(row) => {
          setSelected(row);
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            enable: checkArray(selected),
            allowDownload: handleCheckDownload(),
            allowDelete: handleCheckDelete(),
            executeDelete: checkConfirmDelete,
            onDownload: handleDownload,
          },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        sx={{
          ".MuiTablePagination-displayedRows": {
            marginTop: "1em",
            marginBottom: "1em",
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
            {
              marginTop: "1em",
              marginBottom: "1em",
            },
        }}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <DeleteButtonComponent onClick={handleDelete}>
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        open={Boolean(deleteMessage)}
      >
        <DialogTitle>Delete Result</DialogTitle>
        <DialogContent>
          {deleteMessage && checkArray(deleteMessage.res)
            ? deleteMessage.res.map((el, i) => (
                <Typography key={i}>
                  {el.status === 200
                    ? "success"
                    : `${el?.message}: ${el?.errors?.error}`}
                </Typography>
              ))
            : null}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleRestore}>
            Restore
          </Button>
          <Button
            onClick={() => {
              setDeleteMessage(null);
              onUpdate();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
