import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import Save from "@mui/icons-material/Save";

import SpreadsheetEditor from "../common/spreadsheetEditor";
import { EntityApi } from "@unity/components";
import config from "../config";
import Breadcrumbs from "../common/breadcrumbs";
import DocEditor from "../common/docEditor";
import { useApiLoader } from "../services/apiLoaderContext";

const NameDialog = ({ open, onClose, name, setName, onClick }) => (
  <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogContent>
      <TextField
        label="File Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        variant="contained"
        onClick={() => {
          onClick();
          onClose();
        }}
      >
        Save
      </Button>
    </DialogActions>
  </Dialog>
);

const SaveMenu = ({ onSaveCopy }) => (
  <Box>
    <Button
      onClick={onSaveCopy}
      startIcon={<Save />}
      variant="contained"
      sx={{ textTransform: "none", width: 140, ml: 1 }}
    >
      Save a copy
    </Button>
  </Box>
);

export default function FileEditor({ type, parentId }) {
  const { setSnackbar } = useApiLoader();
  const location = useLocation();
  const { file } = useParams();
  const fileArr = file && file.split("&");
  const fileId = fileArr && fileArr[0];

  const [data, setData] = useState(null);
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    const getPermission = async () => {
      try {
        const res = await EntityApi.getEntity(
          parentId,
          config.entityIntranetTypeId
        );
        if (res && res.status === 200) {
          setPermission(res.data?.permission);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getPermission();
  }, [parentId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await EntityApi.getEntity(
          fileId,
          config.entityIntranetTypeId
        );
        if (res && res.status === 200) {
          setData({ ...res.data });
        } else {
          setData(null);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (file) {
      getData();
    } else {
      setData(null);
    }
  }, [file, fileId]);

  const handleRes = (res) => {
    if (res && res.status === 200) {
      setSnackbar({ open: true, message: "success", success: true });
    } else {
      setSnackbar({
        open: true,
        message: "Something went wrong",
        success: false,
      });
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Breadcrumbs pathname={location.pathname} />
        {type === "excel" ||
        (data &&
          data.fileType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ? (
          <SpreadsheetEditor
            entity={data}
            parentId={parentId}
            permission={permission}
            handleRes={handleRes}
            NameDialog={NameDialog}
            SaveMenu={SaveMenu}
          />
        ) : type === "word" ||
          (data &&
            (data.fileType === "application/msword" ||
              data.fileType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) ? (
          <DocEditor
            entity={data}
            parentId={parentId}
            permission={permission}
            handleRes={handleRes}
            NameDialog={NameDialog}
            SaveMenu={SaveMenu}
          />
        ) : null}
      </Stack>
    </>
  );
}
