import { IconButton, Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";

import { useView } from "../services/viewContext";
import React from "react";

export default function ViewToggle() {
  const { view, setView } = useView();
  return (
    <Tooltip title="toggle view">
      <IconButton
        onClick={() => setView((prev) => (prev === "list" ? "grid" : "list"))}
        className="tour-intranetviewtoggle"
      >
        {view === "list" ? <ListIcon /> : <GridViewIcon />}
      </IconButton>
    </Tooltip>
  );
}
