import React, { useState } from "react";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import AccountTree from "@mui/icons-material/AccountTree";
import { useTheme } from "@mui/material/styles";

import Breadcrumbs from "./breadcrumbs";
import ViewToggle from "./viewToggle";
import TreeView from "./treeview";

export default function Container({
  pathname,
  actions,
  update,
  children,
  root,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Paper elevation={0} sx={{ pt: 1, pl: 1 }}>
      <Grid container spacing={1} alignItems="center">
        {pathname && (
          <Grid item>
            <Breadcrumbs pathname={pathname} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1}>
                {actions
                  ? actions.map((el, i) => (
                      <Grid item key={i}>
                        {el}
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="toggle tree">
                  <IconButton
                    onClick={() =>
                      matches
                        ? setOpen((prev) => !prev)
                        : setDrawerOpen((prev) => !prev)
                    }
                    className="tour-intranettreetoggle"
                  >
                    <AccountTree />
                  </IconButton>
                </Tooltip>
                <ViewToggle />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {matches && open ? (
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TreeView update={update} root={root} />
              </Grid>
              <Grid item xs={9}>
                {children}
              </Grid>
            </Grid>
          ) : (
            children
          )}
        </Grid>
      </Grid>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": { width: 240 },
          display: { xs: "block", md: "none", p: 1 },
        }}
      >
        <TreeView
          update={update}
          onItemClick={() => setDrawerOpen(false)}
          root={root}
        />
      </Drawer>
    </Paper>
  );
}
