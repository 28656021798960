import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  SnackNotificationComponent,
  AuthProvider,
  TenantApi,
} from "@unity/components";
import { ViewContext } from "./services/viewContext";
import { ApiLoaderContext } from "./services/apiLoaderContext";
import Loading from "./common/loading";
import Routes from "./routes";
import config from "./config";

export default function Root(props) {
  const [view, setView] = useState("list"); // either grid or list
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    message: "",
    success: "",
  });
  const [personnelPrivate, setPersonnelPrivate] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const res = await TenantApi.moduleSettingsIndexForCategory({
        category_type: "intranet",
      });
      if (res) {
        setPersonnelPrivate(res.data.data[0]?.settings?.private);
      }
    };
    getData();
  }, []);

  const handleUpdateView = (val) => setView(val);

  const handleCloseSnackbar = () => {
    setMessage({ open: false, message: "", success: "" });
  };

  let display = (
    <ViewContext.Provider value={{ view, setView: handleUpdateView }}>
      <ApiLoaderContext.Provider
        value={{
          setLoading,
          setSnackbar: setMessage,
        }}
      >
        <AuthProvider
          module_alias={config.resourceName}
          {...props}
          personnelPrivate={personnelPrivate}
        >
          <Router>
            <Route
              render={({ location }) => (
                <TransitionGroup className="transition-group">
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <section className="route-section">
                      <Routes />
                      <SnackNotificationComponent
                        open={message.open}
                        message={message.message}
                        success={message.success}
                        onClose={handleCloseSnackbar}
                      />
                      <Loading open={loading} />
                    </section>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Router>
        </AuthProvider>
      </ApiLoaderContext.Provider>
    </ViewContext.Provider>
  );

  return display;
}
